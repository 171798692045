const ID_TOKEN_KEY = "id_token";
const END_TOKEN_KEY = "end_token";

export const getUser = () => {
  let userString = window.localStorage.getItem(ID_TOKEN_KEY);
  return userString ? JSON.parse(userString) : userString;
};

export const saveUser = (user) => {
  window.localStorage.setItem(ID_TOKEN_KEY, JSON.stringify(user));
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem(END_TOKEN_KEY);
};

export default { getUser, saveUser, destroyToken };
